@media (max-width: 650px) {
    .navigation {
        width: 100%;
    }
}

.navigation {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
}

.navigation .navBar {
    text-align: center;
}

.navigation .navBar:before, .navigation .navBar:after {
    content: "";
    height: 1px;
    background: linear-gradient(to right, rgba(80, 80, 80, 0) 0%, #c8c8c8 50%, rgba(80, 80, 80, 0) 100%);
    display: block;
}

.navigation .navBar li {
    display: inline-block;
}

.navigation .navBar li .navLink {
    display: block;
    color: #A0EADE;
    text-align: center;
    padding: 1px 12px;
}
