@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
html, body, body div, span, object, iframe, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-weight: inherit;
    font-style: normal;
    vertical-align: baseline;
    outline: 0;
}

/* HTML5 RESETS FOR OLDER BROWSERS */
article, aside, figure, figure img, hgroup, footer, header, nav, section {
    display: block;
}

/*ol, ul { list-style: none; }*/
blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input, select {
    vertical-align: middle;
}

a img {
    border: 0;
}
body {
    font-family: "Anonymous Pro", monospace;
    font-size: 100%;
    background-color: #2B3847;
    color: #f3f1f3;
    line-height: 150%;
    display: flex;
    justify-content: center;
    margin: 0;
}

.wrapper {
    width: 650px;
    margin: auto;
}



@media (max-width: 650px) {
    .navigation {
        width: 100%;
    }
}

.navigation {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
}

.navigation .navBar {
    text-align: center;
}

.navigation .navBar:before, .navigation .navBar:after {
    content: "";
    height: 1px;
    background: linear-gradient(to right, rgba(80, 80, 80, 0) 0%, #c8c8c8 50%, rgba(80, 80, 80, 0) 100%);
    display: block;
}

.navigation .navBar li {
    display: inline-block;
}

.navigation .navBar li .navLink {
    display: block;
    color: #A0EADE;
    text-align: center;
    padding: 1px 12px;
}

.container {
    margin: 20px;
}

.container p {
    padding: 8px;
}
.blurb {
    margin-left: 8px;
}

.blurb h1 {
    margin-bottom: 0;
    margin-top: 10px;
}

.blurb h3 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.wrapper {
    width: 650px;
    margin: auto;
}

