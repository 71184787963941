html, body, body div, span, object, iframe, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-weight: inherit;
    font-style: normal;
    vertical-align: baseline;
    outline: 0;
}

/* HTML5 RESETS FOR OLDER BROWSERS */
article, aside, figure, figure img, hgroup, footer, header, nav, section {
    display: block;
}

/*ol, ul { list-style: none; }*/
blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input, select {
    vertical-align: middle;
}

a img {
    border: 0;
}